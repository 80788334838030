export const EMPTY_ACTION = 'EMPTY_ACTION';

export const INIT = {
  I18N: 'INIT_I18N',
};

export const CLEAR = {
  CONFIRM_PASSWORD: 'CLEAR_CONFIRM_PASSWORD',
  CONFIRM_PASSWORD_ERROR: 'CLEAR_CONFIRM_PASSWORD_ERROR',
  CURRENT_PASSWORD: 'CLEAR_CURRENT_PASSWORD',
  CURRENT_PASSWORD_ERROR: 'CLEAR_CURRENT_PASSWORD_ERROR',
  CURRENT_PASSWORD_INDICATOR: 'CURRENT_PASSWORD_INDICATOR',
  CUSTOMER_GENDER_ERROR: 'CLEAR_CUSTOMER_GENDER_ERROR',
  FIRST_NAME_ERROR: 'CLEAR_FIRST_NAME_ERROR',
  LAST_NAME_ERROR: 'CLEAR_LAST_NAME_ERROR',
  NEW_PASSWORD: 'CLEAR_NEW_PASSWORD',
  NEW_PASSWORD_ERROR: 'CLEAR_NEW_PASSWORD_ERROR',
  PROCESSING_MODAL_TEXT: 'CLEAR_PROCESSING_MODAL_TEXT',
  PROFILE_FORM_MESSAGE: 'CLEAR_PROFILE_FORM_MESSAGE',
  RETURN_DOCUMENTS: 'CLEAR_RETURN_DOCUMENTS',
  RETURN_DOCUMENTS_ERROR: 'CLEAR_RETURN_DOCUMENTS_ERROR',
  RETURN_ID: 'CLEAR_RETURN_ID',
  RETURN_ITEM: 'CLEAR_RETURN_ITEM',
  RETURN_REASONS_SELECTED: 'CLEAR_RETURN_REASONS_SELECTED',
  RETURN_REQUEST_EXPIRED_ERROR: 'CLEAR_RETURN_REQUEST_EXPIRED_ERROR',
  RETURN_SUMMARY: 'CLEAR_RETURN_SUMMARY',
  SHIP_CONFIRMATION_NUMBER: 'CLEAR_SHIP_CONFIRMATION_NUMBER',
  TRACK_SHIPMENT_DATA: 'CLEAR_TRACK_SHIPMENT_DATA',
  TRACK_SHIPMENT_MESSAGE: 'CLEAR_TRACK_SHIPMENT_MESSAGE',
};

export const TOGGLE = {
  RETURN_POLICY_MODAL: 'TOGGLE_RETURN_POLICY_MODAL',
};

export const UPDATE = {
  BASE_PATH_WEB: 'UPDATE_BASE_PATH_WEB',
  CONFIRM_PASSWORD: 'UPDATE_CONFIRM_PASSWORD',
  CONFIRM_PASSWORD_ERROR: 'UPDATE_CONFIRM_PASSWORD_ERROR',
  COUNTRY: 'UPDATE_COUNTRY',
  CURRENT_PASSWORD: 'UPDATE_CURRENT_PASSWORD',
  CURRENT_PASSWORD_ERROR: 'UPDATE_CURRENT_PASSWORD_ERROR',
  CUSTOMER_GENDER: 'UPDATE_CUSTOMER_GENDER',
  CUSTOMER_GENDER_ERROR: 'UPDATE_CUSTOMER_GENDER_ERROR',
  EMAIL: 'EMAIL',
  FIRST_NAME: 'UPDATE_FIRST_NAME',
  FIRST_NAME_ERROR: 'UPDATE_FIRST_NAME_ERROR',
  KEYS: 'KEYS',
  LAST_NAME: 'UPDATE_LAST_NAME',
  LAST_NAME_ERROR: 'UPDATE_LAST_NAME_ERROR',
  PAPERLESS_RETURN_FLAG: 'UPDATE_PAPERLESS_RETURN_FLAG',
  MKT_COUNTRY: 'UPDATE_MKT_COUNTRY',
  NEW_PASSWORD: 'UPDATE_NEW_PASSWORD',
  NEW_PASSWORD_ERROR: 'UPDATE_NEW_PASSWORD_ERROR',
  NEW_PASSWORD_INDICATOR: 'NEW_PASSWORD_INDICATOR',
  ORDERS: 'UPDATE_ORDERS',
  PAGINATION: 'UPDATE_PAGINATION',
  PROCESSING_MODAL_TEXT: 'UPDATE_PROCESSING_MODAL_TEXT',
  PROFILE_FORM_MESSAGE: 'UPDATE_PROFILE_FORM_MESSAGE',
  RETURN_DOCUMENTS: 'UPDATE_RETURN_DOCUMENTS',
  RETURN_DOCUMENTS_ERROR: 'UPDATE_RETURN_DOCUMENTS_ERROR',
  RETURN_ID: 'UPDATE_RETURN_ID',
  RETURN_ITEM: 'UPDATE_RETURN_ITEM',
  RETURN_REASONS: 'UPDATE_RETURN_REASONS',
  RETURN_REASONS_SELECTED: 'UPDATE_RETURN_REASONS_SELECTED',
  RETURN_REASON_ID: 'UPDATE_RETURN_REASON_ID',
  RETURN_REQUEST_EXPIRED_ERROR: 'UPDATE_RETURN_REQUEST_EXPIRED_ERROR',
  RETURN_RESTRICTED_CATEGORIES: 'UPDATE_RETURN_RESTRICTED_CATEGORIES',
  RETURN_SHIPPING_DATE: 'UPDATE_RETURN_SHIPPING_DATE',
  RETURN_SHIPPING_HOUR: 'UPDATE_RETURN_SHIPPING_HOUR',
  RETURN_SHIPPING_TYPE: 'UPDATE_RETURN_SHIPPING_TYPE',
  RETURN_SUMMARY: 'UPDATE_RETURN_SUMMARY',
  SHIPPING_DATES: 'UPDATE_SHIPPING_DATES',
  SHIPPING_HOURS: 'UPDATE_SHIPPING_HOURS',
  SHIP_CONFIRMATION_NUMBER: 'UPDATE_SHIP_CONFIRMATION_NUMBER',
  TRACK_SHIPMENT_DATA: 'UPDATE_TRACK_SHIPMENT_DATA',
  TRACK_SHIPMENT_MESSAGE: 'UPDATE_TRACK_SHIPMENT_MESSAGE',
};
