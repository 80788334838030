export type BooleanString = 'Y' | 'N';

export interface IOrderItem {
  brand: string;
  categoryId: string;
  countryCode: string;
  currency: string;
  imgSrc: string;
  index: string;
  itemColor: string;
  model: string;
  modelNumber: string;
  orderDate: string;
  price: string;
  productStoreOrderId: string;
  productVersionOptionId: string;
  quantity: number;
  returnId: string | null;
  returnStatus: number | null;
  size: string;
  sizeType: string;
  storeOrderId: string;
  storeOrderNumber: string;
  storeOrderStatus: {
    order_status_id: string;
    status: string;
  };
}

export interface IOrder {
  currency: string;
  imgSrc: string;
  orderDate: string;
  orderId: string;
  orderNumber: string;
  orderStatus: {
    order_status_id: string;
    status: string;
  };
  price: string;
  productVersionOptionIds: string[];
  quantity: number;
  storeOrderIds: string[];
  storeOrderNumbers: string[];
  trackOrderSelected(order: IOrder): void;
}

export interface IOrderDetail extends IOrder {
  caTax: string;
  countryCode: string;
  customs: string;
  hasInvoices: boolean;
  isTaxUeZone: BooleanString;
  items: IOrderItem[];
  promotion: string;
  shipping: string;
  subtotal: string;
  tax: string;
  taxRate: string;
  vat: string;
  vatRate: string;
}

export interface IPagination {
  total: number;
}

export const OrderStatusId = {
  accepted: '2',
  cancelled: '5',
  completed: '4',
  delivered: '6',
  processing: '1',
  shipped: '3',
};

export enum Gender {
  Man = 'M',
  Woman = 'F',
}

export const ReturnReason = {
  changedMind: '9',
  colorCompletelyDifferent: '15',
  colorHueSeemedDifferent: '13',
  faultyDirtyItem: '8',
  itemTooBig: '1',
  itemTooSmall: '2',
  materialSeemedDifferent: '14',
  productCompletelyDifferent: '17',
  sizeOnLabelDifferentFromOneOrdered: '16',
};

export enum ReturnType {
  DROP_OFF = 'drop_off',
  PICK_UP = 'pick_up',
}

export interface IDropdownOption {
  label: string;
  value: string;
}

export interface IReturnReason {
  reason: string;
  reasonCategory: string;
  reasonId: string;
}

export interface IReturnReasonSelected {
  productStoreOrderId: string;
  reason_id: string;
}

export interface IReturnSummary {
  rowsList: {label:string, amount: number}[];
  total: string;
}

export interface IReturnDocuments {
  awb: string;
  invoice: string;
  returnNumber: string;
  storeOrderNumber: string;
  waybill: string;
}

export interface ITrackShipment {
  date: string;
  location: string;
  status: string;
}

export interface IAppStore {
  basePathWeb: string;
  confirmPassword: string;
  confirmPasswordError: string;
  country: string;
  currentPassword: string;
  currentPasswordError: string;
  email: string;
  firstName: string;
  firstNameError: string;
  gender: Gender;
  genderError: string;
  i18n: any;
  keys: any;
  lastName: string;
  lastNameError: string;
  paperlessReturnFlag: boolean;
  mktCountry: string;
  newPassword: string;
  newPasswordError: string;
  orders: IOrderDetail[];
  pagination: IPagination;
  passwordIndicator: any;
  processingModalText: string;
  profileFormSuccessMessage: string;
  returnDocuments: IReturnDocuments;
  returnDocumentsError: boolean;
  returnId: string;
  returnItem: IOrderItem;
  returnPolicyModal: boolean;
  returnReasonId: string;
  returnReasons: IReturnReason[];
  returnReasonsSelected: IReturnReasonSelected[];
  returnRequestExpiredError: boolean;
  returnRestrictedCategories: string[];
  returnShippingDate: string;
  returnShippingHour: string;
  returnShippingType: string;
  returnSummary: IReturnSummary;
  shipConfirmationNumber: string;
  shippingDates: IDropdownOption[];
  shippingHours: IDropdownOption[];
  trackShipmentData: ITrackShipment[];
  trackShipmentMessage: string;
}

export enum API_ERROR {
  STORE_ORDER_DOEST_EXIST = 1,
  STORE_ORDER_PRODUCT_DOEST_EXIST = 2,
  AIR_WAYBILL = 3,
  MISSING_PICKUP_DATE = 4,
  MISSING_OR_INVALID_PICKUP_TIME = 5,
  RETURN_ALREADY_ISSUED = 6,
  MISSING_OR_INVALID_RETURN_REASON = 7,
  UNKNOWN = 8,
  PROFILE_DATA_INVALID = 9,
  PASSWORDS_NOT_MATCHING = 10,
  WRONG_CURRENT_PASSWORD = 16,
  RETURN_EXPIRED = 14,
}

export interface IProfilePayload {
  confirmPassword?: string;
  currentPassword?: string;
  firstName: string;
  gender?: string;
  lastName: string;
  newPassword?: string;
}

export interface IUiTracking {
  type?: string;
}

export interface IUserTracking {
  people_prop_method?: string;
  property_string_value?: string;
  property_to_update?: string;
}

export interface IStoreOrderTracking {
  shipping_status?: string;
  store_order_number?: string;
  store_order_status?: string;
}

export interface IProductTracking {
  product_version_option_id?: string;
}

export interface IOrderTracking {
  awb?: string;
  currency?: string;
  custom_duties?: string;
  duties?: string;
  grand_total?: string;
  invoice_number?: string;
  order_date?: string;
  order_id?: string;
  order_number?: string;
  order_status?: string;
  page?: string;
  product_version_option_id?: string;
  products?: string[];
  promotion?: string;
  quantity?: number;
  return_number?: string;
  sales_tax?: string;
  shipping?: string;
  shipping_status?: string;
  store_orders?: IStoreOrderTracking[];
  subtotal?: string;
  vat?: string;
}

export interface IReturnOrderTracking {
  awb?: string;
  reason?: string;
  return_number?: string;
  store_order?: IStoreOrderTracking;
  total_refund?: string;
}

export interface IMyAccountTracking {
  orders?: IOrderTracking[];
  page?: string;
}
