import { Reducer } from 'redux';
import { CLEAR, UPDATE } from '../action/type';

export const firstName: Reducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE.FIRST_NAME:
      return action.value;
    default:
      return state;
  }
};

export const lastName: Reducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE.LAST_NAME:
      return action.value;
    default:
      return state;
  }
};

export const firstNameError: Reducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE.FIRST_NAME_ERROR:
      return action.value;
    case CLEAR.FIRST_NAME_ERROR:
      return '';
    default:
      return state;
  }
};

export const lastNameError: Reducer = (state = '', action) => {
  switch (action.type) {
    case UPDATE.LAST_NAME_ERROR:
      return action.value;
    case CLEAR.LAST_NAME_ERROR:
      return '';
    default:
      return state;
  }
};
